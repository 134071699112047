import React from 'react'
import Layout from '../layout/layout'
import Menu from '../components/menu'

export default function NotFound() {
  return (
    <Layout title="404 - Note Not Found 😉">
      <div className="column is-three-fifths">
          <h3>Can't find the note you are looking for</h3>

          <p class="lead">It may be a note which is not ready yet to be shared publicly.</p>
      </div>
      <Menu />
    </Layout>
  )
}
